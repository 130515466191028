import React, { useCallback, useMemo, useState } from 'react';
import { UsersAndGroupsExpandablePickerContext } from './UsersAndGroupsExpandablePickerContext';
import { UsersAndGroupsExpandablePickerContextProps } from './UsersAndGroupsExpandablePickerContextProvider.types';
import { useToggleSelection } from './hooks';
import { isNil } from 'lodash';
import { UsersAndGroupsExpandablePickerContextType } from './UsersAndGroupsExpandablePickerContext.types';
import { USERS_AND_GROUPS_MAXIMUM_USERS_SELECTION } from './UsersAndGroupsExpandablePickerContextProvider.consts';
import { SelectUserOption } from 'utils/types/selectInput.types';

export const UsersAndGroupsExpandablePickerContextProvider = ({
  children,
  selectedItems,
  availableItems,
  setSelectedItems,
  searchFilter,
  limits,
  errors,
  getPopupContainer,
  editModeOptions,
  minMaxInfoErrorPairs,
  onGroupMembersLoad,
  markGroupMembersAsNonReselectable,
}: UsersAndGroupsExpandablePickerContextProps) => {
  const { toggleItemSelectionState } = useToggleSelection(setSelectedItems);
  const [newlySelectedGroupMembers, setNewlySelectedGroupMembers] = useState(
    new Map<number, SelectUserOption>()
  );

  const markGroupMemberAsNewlySelected = useCallback(
    (groupMember: SelectUserOption) => {
      setNewlySelectedGroupMembers(prevState => {
        return new Map(prevState).set(groupMember.id, groupMember);
      });
    },
    []
  );

  const maxUsersLimitReached = useMemo(() => {
    const countLimits = limits.selectionCountLimits;
    return (
      !isNil(countLimits) &&
      !isNil(countLimits.maxUsers) &&
      countLimits.maxUsers <= selectedItems.users.size
    );
  }, [limits.selectionCountLimits, selectedItems.users.size]);

  const absoluteMaxUsersLimitReached = useMemo(
    () => selectedItems.users.size >= USERS_AND_GROUPS_MAXIMUM_USERS_SELECTION,
    [selectedItems.users.size]
  );

  const contextValue: UsersAndGroupsExpandablePickerContextType = useMemo(
    () => ({
      searchText: searchFilter ?? '',
      limits: limits,
      selectedItems: selectedItems,
      availableItems: availableItems,
      errors: errors,
      toggleItemSelectionState: toggleItemSelectionState,
      getPopupContainer,
      editModeOptions,
      isMaxUsersLimitReached:
        maxUsersLimitReached || absoluteMaxUsersLimitReached,
      minMaxInfoErrorPairs,
      onGroupMembersLoad,
      newlySelectedGroupMembers: newlySelectedGroupMembers,
      markGroupMemberAsNewlySelected: markGroupMemberAsNewlySelected,
      markGroupMembersAsNonReselectable: markGroupMembersAsNonReselectable,
    }),
    [
      searchFilter,
      limits,
      selectedItems,
      availableItems,
      errors,
      toggleItemSelectionState,
      getPopupContainer,
      editModeOptions,
      maxUsersLimitReached,
      absoluteMaxUsersLimitReached,
      minMaxInfoErrorPairs,
      onGroupMembersLoad,
      newlySelectedGroupMembers,
      markGroupMemberAsNewlySelected,
      markGroupMembersAsNonReselectable,
    ]
  );

  return (
    <UsersAndGroupsExpandablePickerContext.Provider value={contextValue}>
      {children}
    </UsersAndGroupsExpandablePickerContext.Provider>
  );
};
