import React, { useCallback, useEffect } from 'react';
import { FormValue, Widget } from 'alx-dynamic-form';
import { FormItem } from 'components/lib/Form';
import { StandardFieldWrapper } from 'components/FormPreview2/components/StandardFieldWrapper';
import { FormLabelWithToolTip } from 'pages/TaskTemplates/components/FormLabel';
import {
  SelectUserAndGroupOptionMap,
  UsersAndGroupsSelectLimits,
} from 'utils/types/selectInput.types';
import useCustomWidgetsStyles from '../../customWidgetsStyles';
import { useLocation } from 'react-router-dom';
import MinMaxInfo from 'components/MinMaxInfo';
import Tooltip from 'components/lib/Tooltip';
import clsx from 'clsx';
import { useCustomUserWidgetStyles } from './CustomUserWidget.styles';
import { UsersAndGroupsExpandableSelect } from '../../../../UsersAndGroupsSelection/UsersAndGroupsExpandableSelect';
import { MinMaxInfoLabelType } from 'components/MinMaxInfo/types';
import { useObjectClassUserFieldOptions } from 'components/UsersAndGroupsSelection/hooks';
import {
  createUsersAndGroupsSelectionCountLimits,
  userAndGroupOptionMapToUserFormValue,
} from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/utils';
import { useFieldTouched, useUserFormFieldSelectionOptions } from '../../hooks';
import { ErrorsComponent } from 'components/ErrorsComponent';
import { UsersAndGroupsFormValue } from '../../inPlaceEdit/AdaptedInPlaceEditUser/AdaptedInPlaceEditUser.types';
import { usersAndGroupsFormValueToSelectionMap } from './utils';
import { useCustomUserWidgetValidation } from './hooks';
import { getEligibleUserOptions } from 'components/UsersAndGroupsSelection/utils/getEligibleUserOptions';

export const CustomUserWidget: Widget = ({
  value: valueGeneric,
  required,
  label,
  fieldAlias,
  description,
  errors: formErrors,
  onChange: setValue,
  reValidateField,
  additionalFieldProps,
  fieldId,
  maxUsers,
  minUsers,
  minGroups,
  allowGroupMemberSelection,
  allowGroupSync,
  maxGroups,
  disabled,
}) => {
  const value = valueGeneric as UsersAndGroupsFormValue | null | undefined;

  const sharedStyles = useCustomWidgetsStyles();
  const styles = useCustomUserWidgetStyles();

  const { isFieldTouched, onFieldBlur } = useFieldTouched();

  const { search } = useLocation();
  const classId =
    additionalFieldProps?.classId ?? new URLSearchParams(search).get('classId'); //if classId is provided by additionalFieldProps (standalone form), use it, otherwise get it from url params

  const limits: UsersAndGroupsSelectLimits = {
    selectionCountLimits: createUsersAndGroupsSelectionCountLimits(
      minUsers,
      maxUsers,
      minGroups,
      maxGroups
    ),
    isAllowedToSelectIndividualMembers: allowGroupMemberSelection ?? false,
    isAllowedToSyncGroupMembers: allowGroupSync ?? false,
  };

  const {
    validateFulfillmentPossibility,
    scheduleRevalidation,
    errors,
    minGroupsError,
    minUsersError,
    tooltip,
    shouldDisableDueToValidation,
  } = useCustomUserWidgetValidation(
    value,
    label,
    formErrors,
    required,
    limits,
    reValidateField
  );

  const { isLoading, options, fetchOptions } = useObjectClassUserFieldOptions(
    fieldId,
    classId,
    fetchedOptions => {
      const eligibleOptions = {
        groups: fetchedOptions.groups,
        users: getEligibleUserOptions(fetchedOptions.users, value),
      };

      validateFulfillmentPossibility(eligibleOptions);
    }
  );

  const {
    getCurrentOptions,
    onLoadedUserOptions,
  } = useUserFormFieldSelectionOptions(options);

  const selection = usersAndGroupsFormValueToSelectionMap(
    value,
    getCurrentOptions(),
    additionalFieldProps.meta
  );

  // This little monster converts selection as map to form value and sets the value with a set
  // state pattern using the previous value. Selection state is controlled by value and setValue
  // of the widget props while we retain conversion to selection map further down
  // the components.
  const transitionSelectionState = useCallback(
    (
      newSelection: React.SetStateAction<SelectUserAndGroupOptionMap>,
      previousFormValue: FormValue
    ) => {
      // we create selection map from the previous form value and the
      // users data that we have (meta, object class field options and loaded group members)
      const previousSelection = usersAndGroupsFormValueToSelectionMap(
        previousFormValue as UsersAndGroupsFormValue | null | undefined,
        getCurrentOptions(),
        additionalFieldProps.meta
      );

      // we use created selection map to transition state to the new map that represents
      // the current selection as map.
      const currentSelection =
        typeof newSelection === 'function'
          ? newSelection(previousSelection)
          : newSelection;

      // we create new form value converted from the current selection map.
      const newFormValue = userAndGroupOptionMapToUserFormValue(
        currentSelection
      );

      if (isFieldTouched) {
        scheduleRevalidation();
      }

      // new form value is being set as a new state of the widget
      return newFormValue;
    },
    [
      additionalFieldProps.meta,
      getCurrentOptions,
      isFieldTouched,
      scheduleRevalidation,
    ]
  );

  const handleSetSelection = useCallback(
    (newSelection: React.SetStateAction<SelectUserAndGroupOptionMap>) => {
      setValue(previousValue => {
        return transitionSelectionState(newSelection, previousValue);
      });
    },
    [setValue, transitionSelectionState]
  );

  const onDropdownOpenChange = useCallback(
    isOpen => {
      if (isOpen) {
        return;
      }

      reValidateField();
    },
    [reValidateField]
  );

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  const isFieldDisabled = shouldDisableDueToValidation || disabled;

  return (
    <>
      <FormLabelWithToolTip
        dynamicSpacing
        required={required}
        className={clsx([
          sharedStyles.inputLabel,
          sharedStyles.inputLabelEllipsis,
        ])}
        label={label}
      />
      <FormItem
        dynamicSpacing
        validateStatus={errors.length > 0 ? 'error' : undefined}
      >
        <StandardFieldWrapper
          description={description}
          errors={errors}
          errorLimits={{ blacklist: [minUsersError, minGroupsError] }}
        >
          <div
            className={styles.selectStyles}
            data-testid={`users-${fieldAlias}`}
          >
            <Tooltip
              title={tooltip}
              shouldBeRendered={!!tooltip}
              getPopupContainer={element =>
                element.parentElement ?? document.body
              }
            >
              <UsersAndGroupsExpandableSelect
                isLoadingOptions={isLoading}
                limits={limits}
                setSelection={handleSetSelection}
                onDropdownOpenChange={onDropdownOpenChange}
                options={options}
                selection={selection}
                required={required}
                isFieldDisabled={isFieldDisabled}
                errors={errors}
                onBlur={onFieldBlur}
                reValidateField={reValidateField}
                onGroupMembersLoad={onLoadedUserOptions}
                interactableAvatars={isFieldDisabled}
                searchBarBehavior={
                  additionalFieldProps.userFieldSearchBarBehavior
                }
                markGroupMembersAsNonReselectable={
                  additionalFieldProps.markGroupMembersAsNonReselectable
                }
                minMaxInfoErrorPairs={
                  new Map<MinMaxInfoLabelType, string>([
                    [MinMaxInfoLabelType.Users, minUsersError],
                    [MinMaxInfoLabelType.Groups, minGroupsError],
                  ])
                }
              />
            </Tooltip>
          </div>
          <div className={styles.validationListing}>
            <MinMaxInfo
              maximum={maxGroups ?? undefined}
              minimum={minGroups ?? undefined}
              className={styles.minMaxInfoOverride}
              labelType={MinMaxInfoLabelType.Groups}
              checkboxField
            />
            <ErrorsComponent rawErrors={errors} whitelist={[minGroupsError]} />
            <MinMaxInfo
              maximum={maxUsers ?? undefined}
              minimum={minUsers ?? undefined}
              className={styles.minMaxInfoOverride}
              labelType={MinMaxInfoLabelType.Users}
              checkboxField
            />
            <ErrorsComponent rawErrors={errors} whitelist={[minUsersError]} />
          </div>
        </StandardFieldWrapper>
      </FormItem>
    </>
  );
};
